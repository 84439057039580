module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"abcroofingusa.com","short_name":"abcroofingusa.com","start_url":"/","version":"0.0.2","permissions":["gcm","storage","notifications"],"gcm_sender_id":"395966986145","gcm_user_visible_only":true,"display":"standalone","icon":"/opt/atlassian/pipelines/agent/build/data/sites/homeservices/abcroofingusa.com/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9cdb45233234e081d0a7ad2f934f2a7e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
